
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Home } from "../pages/Home";
import { Support } from "../pages/Support";
import { CourtesyLanding } from "../pages/CourtesyLanding";
import ExperienceAwardLanding from "../pages/ExperienceAwardLanding";
import DigitalAwardLanding from "../pages/DigitalAwardLanding";
import { useAuthContext } from "../utils/AuthContext";
import { useUser } from "../utils/useUserExist";
import { PageNotFound } from "../pages/PageNotFound";
import { useEffect } from "react";
import { track } from "../utils/Tracking";

/**
 * AppRouter component that defines the application's routing.
 * 
 * This component uses `react-router-dom` to set up the routes for the application.
 * 
 * @component
 * @example
 * // Usage in a parent component:
 * // <AppRouter />
 * 
 * @returns {JSX.Element} The JSX element containing the routes.
 */

const AppRouter = (): JSX.Element => {
  const { isAuth, responseError } = useAuthContext();
  const user = useUser();

  const location = useLocation();

  useEffect(() => {
      const path = location.pathname.split('/')[1];

      switch (path) {
        case '':
          if (isAuth || user) {
            track({
              touchpoint: 'WEB',
              alias_page: 'alias_payandwin_landing_gioco',
              section: 'Gioca',
              title_page: 'Landing Gioco',
              site_section: 'Pay and Win'
            })
          } else {
            track({
              touchpoint: 'WEB',
              alias_page: 'alias_payandwin_iscriviti_ora',
              section: 'Iscrizione',
              title_page: 'Iscriviti Ora',
              site_section: 'Pay and Win'
            })
          }
          break;
        case 'experience':
          track({
            touchpoint: 'WEB',
            alias_page: 'alias_payandwin_dettaglio_premio',
            section: 'Premi',
            info_prodotto_servizio_motivo: `experience-${location.pathname.split('/')[2]}`,
            title_page: 'Dettaglio Premio',
            site_section: 'Pay and Win'
          })
          break;
        case 'digital-award-landing':
          track({
            touchpoint: 'WEB',
            alias_page: 'alias_payandwin_dettaglio_premio',
            section: 'Premi',
            info_prodotto_servizio_motivo: `voucher-${location.pathname.split('/')[2]}`,
            title_page: 'Dettaglio Premio',
            site_section: 'Pay and Win'
          })
          break;
        case 'support':
          track({
            touchpoint: 'WEB',
            alias_page: 'alias_payandwin_faq',
            section: 'FAQ',
            title_page: 'FAQ',
            site_section: 'Pay and Win'
          })
          break;
      }
  }, [location, isAuth, user]);

  

  if (responseError === 500) {
    return <CourtesyLanding />;
  }

  return (
    <Routes>
      <Route path="/" element={<Outlet />} >
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/experience/:id" element={<ExperienceAwardLanding />} />
        <Route path="/digital-award-landing/:id" element={<DigitalAwardLanding />} />
        {!(isAuth || user) && <Route path="/courtesy-landing" element={<CourtesyLanding />} />}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}

export default AppRouter