import React from "react";
import "./UnsubscribeCompleted.scss";
import locale from "../../assets/locale.json";
import SimpleContent from "../SimpleContent/SimpleContent";
import unsubscribeConfirm from "../../assets/images/unsubscribe-confirm.webp";
import { ContentProps } from "../../models/content";

export const UnsubscribeCompleted: React.FC<ContentProps> = ({
  changeContent,
  onClose = () => {},
}) => {
  return (
    <div className="pw-unsubscribe-completed-container">
      <SimpleContent
        image={{
          alt: locale.subscriptionConfirmationModal.altImg,
          src: unsubscribeConfirm,
        }}
        title={locale.unsubscribe.unsubscribe_completed.title}
        confirmationButton={{
          className: "pw-unsubscribe-completed-button",
          theme: "blue",
          label: locale.unsubscribe.unsubscribe_completed.confirm_button,
          onClick:  () =>  
            {
              onClose();
              window.dispatchEvent(new Event("userRegistered"));
              window.location.href = 'https://www.poste.it/'
            }
        }}
      
      />
    </div>
  );
};

export default UnsubscribeCompleted;
