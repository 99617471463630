import React, { useCallback, useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import { EnumIcon } from "../../models/enumIcon";
import tryAgainImg from "../../assets/images/try-again.webp";
import { EnumContentModal } from "../../models/content";
import PlayGame from "../PlayGame/PlayGame";
import { ModalActionProps } from "../../models/modalAction";
import ModalGameWinExperienceAward from "../ModalGameWinExperienceAward/ModalGameWinExperienceAward";
import ModalGameWinDigitalAward from "../ModalGameWinDigitalAward/ModalGameWinDigitalAward";
import { environment } from "../../environment/environment";
import { PrizeAwardProps } from "../../models/prizeAward";
import ModalError from "../ModalError/ModalError";
import useModal from "../../utils/useModalUtils";
import ModalLimitReached from "../ModalLimitReached/ModalLimitReached";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../utils/AuthContext";
import { track } from "../../utils/Tracking";

const ModalPlayGame: React.FC<ModalActionProps> = ({
  isOpen,
  onClose,
}) => {
  const [content, setContent] = useState<EnumContentModal>(
    EnumContentModal.PLAY_GAME
  );
  const [prize, setPrize] = useState<PrizeAwardProps>();
  const { tryAgain, youLose } = locale.playGame;
  const changeContent = (content: EnumContentModal) => {
    setContent(content);
  };
  const { ModalType, isModalOpen, openModal, closeModal } = useModal();
  const { title, description, button } = locale.modal_error.errorPlay;
  const navigate = useNavigate();
  const [isFinished, setIsFinished] = useState(false);
  const { setIsAuth } = useAuthContext();

  const fetchDataPlay = useCallback(async () => {
    setIsFinished(false);
    try {
      const response = await fetch(`${environment.apiDomain}/play`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const result = await response.json();

      if (response.status === 200) {
        setPrize(result);
        localStorage.setItem("user", JSON.stringify({ ...result, availableGames: result.availableGames, transactionsProgress: result.transactionsProgress }));
      } else if (response.status === 409) {
        onClose();
        window.dispatchEvent(new Event("userRegistered"));

        track({
          touchpoint: 'WEB',
          alias_page: 'alias_payandwin_limite_giornaliero',
          section: 'KO Page',
          title_page: 'Limite Giornaliero Raggiunto',
          site_section: 'Pay and Win'
        });

        openModal(ModalType.DAYLY_LIMIT_REACHED);
      } else if (response.status === 401) {
        onClose();
        setIsAuth(false);
        localStorage.removeItem("user");
        window.dispatchEvent(new Event("userRegistered"));
        navigate("/courtesy-landing");
      }
      else if (response.status === 500) {
        onClose();
        openModal(ModalType.MODAL_ERROR);
      }
      else {
        onClose();
      }
    }

    catch (error) {
      console.error(error);
      openModal(ModalType.MODAL_ERROR);
    } finally {
      setIsFinished(true);
    }
  }, [openModal, onClose, ModalType, navigate, setIsAuth]);

  useEffect(() => {
    if (isOpen) {
      setIsFinished(false);
      fetchDataPlay();
    } else {
      setContent(EnumContentModal.PLAY_GAME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleLottieComplete = () => {
    if (prize?.success) {
      track({
        touchpoint: 'WEB',
        alias_page: 'alias_payandwin_premio_vinto',
        section: 'Gioca',
        info_prodotto_servizio_motivo: `${prize.prize.type}-${prize.prize.id}`,
        title_page: 'Premio Vinto',
        site_section: 'Pay and Win'
      });
      setContent(EnumContentModal.YOU_WIN);
    }
    else if (prize && !prize?.success && prize?.availableGames > 0) {
      track({
        touchpoint: 'WEB',
        alias_page: 'alias_payandwin_ritenta',
        section: 'Gioca',
        title_page: 'Ritenta',
        site_section: 'Pay and Win'
      });
      setContent(EnumContentModal.TRY_AGAIN);
    }
    else {
      track({
        touchpoint: 'WEB',
        alias_page: 'alias_payandwin_perso',
        section: 'Gioca',
        title_page: 'Perso',
        site_section: 'Pay and Win'
      });
      setContent(EnumContentModal.YOU_LOSE);
    }
  };

  const handleClose = () => {
    if (content === EnumContentModal.PLAY_GAME) {
      return;
    } else {
      onClose();
      window.dispatchEvent(new Event("userRegistered"));
    }
  };

  const closeButtonNotVisible = [
    EnumContentModal.YOU_WIN,
    EnumContentModal.PLAY_GAME,
  ].includes(content);

  const renderContent = () => {
    switch (content) {
      case EnumContentModal.PLAY_GAME:
        return (
          <PlayGame
            changeContent={changeContent}
            onAnimationComplete={handleLottieComplete}
            result={prize}
            isLoop={!isFinished}
          />
        );

      case EnumContentModal.YOU_LOSE:
        return (
          <SimpleContent
            headerLogo={youLose.logo}
            image={{ alt: youLose.altImg, src: tryAgainImg }}
            title={youLose.title}
            description={() => (
              <p className="pw-simple-content-description paragraph-semi-bold-small">
                {youLose.description}
              </p>
            )}
            confirmationButton={{
              theme: "blue",
              label: youLose.buttonLabel,
              onClick: handleClose,
              className: "pw-button-you-lose",
            }}
          />
        );

      case EnumContentModal.TRY_AGAIN:
        return (
          <SimpleContent
            headerLogo={tryAgain.logo}
            image={{ alt: tryAgain.altImg, src: tryAgainImg }}
            title={tryAgain.title}
            description={() => (
              <p className="pw-simple-content-description paragraph-semi-bold-small">
                {tryAgain.description}
              </p>
            )}
            confirmationButton={{
              theme: "blue",
              label: tryAgain.confirmationLabel,
              onClick: () => {
                fetchDataPlay()
                track({
                  touchpoint: 'WEB',
                  alias_page: 'alias_payandwin_gioca',
                  section: 'Gioca',
                  title_page: 'Gioca',
                  site_section: 'Pay and Win'
                });
                setContent(EnumContentModal.PLAY_GAME)
              },
              className: "pw-button-try-again"
            }}
            cancellationButton={{
              theme: "blue underline",
              label: tryAgain.cancellationLabel,
              icon: EnumIcon.arrowLeft,
              onClick: handleClose,
              className: "pw-button-try-again",
            }}
          />
        );

      case EnumContentModal.GAME_WIN_EXPERIENCE_AWARD:
        return prize && <ModalGameWinExperienceAward prize={prize} onClose={handleClose} />;

      case EnumContentModal.GAME_WIN_DIGITAL_AWARD:
        return prize && <ModalGameWinDigitalAward prize={prize} onClose={handleClose} />;

      case EnumContentModal.YOU_WIN:
        return (
          <PlayGame
            isWinVariant
            onClose={handleClose}
            changeContent={changeContent}
            result={prize}
          />
        );

      default:
        return <PlayGame />;
    }
  };

  const heightModal =
    (content === EnumContentModal.TRY_AGAIN || content === EnumContentModal.YOU_LOSE)
      ? "pw-container-modal-try-lose"
      : (content === EnumContentModal.GAME_WIN_EXPERIENCE_AWARD || content === EnumContentModal.GAME_WIN_DIGITAL_AWARD)
        ? "pw-container-modal-game-win"
        : "";

  

  return (
    <>
      {/* Modal Play Game */}
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        fullWidth
        hideCloseButton={closeButtonNotVisible}
        className={heightModal}
      >
        {renderContent()}
      </Modal>
      {/* Modal Limit Reached */}
      <ModalLimitReached
        isOpen={isModalOpen(ModalType.DAYLY_LIMIT_REACHED)}
        onClose={() => closeModal(ModalType.CLOSE)}
      />
      {/* Modal Error */}
      <ModalError
        title={title}
        description={description}
        button={{
          label: button.label,
          theme: "blue",
          onClick: () => closeModal(ModalType.CLOSE),
        }}
        isOpen={isModalOpen(ModalType.MODAL_ERROR)}
      />
    </>
  );
};

export default ModalPlayGame;
