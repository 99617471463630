import { useEffect, useState } from "react";
import { IDigitalVoucher } from "../models/digitalVoucher";
import { IExperienceAwardProps } from "../models/experienceAward";
import { EnumPrizeType } from "../models/prizes";
import { environment } from "../environment/environment";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthContext";

const apiPrizeType = `${environment.apiDomain}/prizes/`;

const useSinglePrizeType = <T extends IDigitalVoucher | IExperienceAwardProps>(
    prizeType: EnumPrizeType,
    id: number
) => {
    const [singlePrize, setSinglePrize] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { setIsAuth } = useAuthContext();

    useEffect(() => {
        const fetchSinglePrize = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${apiPrizeType + prizeType}/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                });
                const data: T = await response.json();
                if (response.ok) {
                    setSinglePrize(data);
                } else if (response.status === 401) {
                    setIsAuth(false);
                    localStorage.removeItem("user");
                    window.dispatchEvent(new Event("userRegistered"));
                    navigate("/courtesy-landing");
                }
            } catch (error) {
                console.error("error", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSinglePrize();
    }, [id, prizeType, navigate, setIsAuth]);
    return { singlePrize, isLoading };
}

export default useSinglePrizeType;