import { EnumTypeProperty } from "../../../models/experienceAward";
import { ImageMap, importAll, getImage } from "../../../utils/getImage";
declare const require: any;

const images: ImageMap = importAll(
    require.context("./", false, /\.(webp)$/)
);

export default images;

export const getPropertiesImg = (valuePropertyImg: EnumTypeProperty) => {
    return (
      Object.entries(images).find(
        ([item]) => getImage(item) === valuePropertyImg?.toLowerCase()
      )?.[1] || ""
    );
  };
