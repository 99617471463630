import "./Footer.scss";
import logoPoste from "../../assets/images/posteitaliane.svg";
import sustainable from "../../assets/images/crescere-sostenibili.svg";
import locale from "../../assets/locale.json";
import { EnumIcon } from "../../models/enumIcon";
import { Button } from "..";
import CookieBanner from "../CoockieBanner/CookieBanner";

const Footer = () => {
    const pwContainer = "pw-container";

    const {
        legalNotes: { text, link: { label, url } },
        socialContent,
        iconLinks,
        description,
        sustainability
    } = locale.footer;

    const iconsType = [
        EnumIcon.facebook,
        EnumIcon.twitter,
        EnumIcon.linkedin,
        EnumIcon.instagram,
        EnumIcon.youtube
    ]

    const iconsContent = iconLinks?.map((link, i) => {
        return (
            <Button
                theme="blue"
                url={link}
                target="_blank"
                ariaLabel={socialContent.iconLabel}
                className="single-icon-link pw-txt-none"
                icon={iconsType[i]}
                key={i}
            />
        )
    });

    return (
        <footer className="pw-footer">
            <div className="legal-notes-container">
                <div className={pwContainer}>
                    <p className="legal-notes sub-paragraph-line-medium ">{text}
                        <a className="notes-link" href={url} download>{label}</a>
                    </p>
                </div>
            </div>
            <div className="social-container">
                <div className={pwContainer}>
                    <div className="social-content">
                        <a href={socialContent.urlPoste} className="logo-poste-link" target="_blank" rel="noreferrer">
                            <img src={logoPoste} className="logo-image" alt={socialContent.alt} />
                        </a>
                        <div className="icon-container">
                            {iconsContent}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-description-container">
                <div className={pwContainer}>
                    <div className="footer-description-content">
                        <a href={sustainability.url} target="_blank" rel="noreferrer" className="sustainability-link">
                            <img src={sustainable} alt={sustainability.alt} className="sustainability-image" />
                        </a>
                        <p className="footer-description paragraph-small">{description}</p>
                        <CookieBanner />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer