import "./DigitalAwardSection.scss";
import SimpleCard from "../SimpleCard/SimpleCard";
import locale from "../../assets/locale.json";
import { useParseText } from "../../utils/useParseText";
import usePrizes from "../../utils/usePrizes";

const DigitalAwardSection = () => {
  const { parseText } = useParseText();
  const { prizes } = usePrizes();

  return (
    <section className="pw-digital-award-section">
      <div className="pw-container">
        <h1
          className="pw-digital-award-section-title"
          dangerouslySetInnerHTML={parseText(
            locale.digital_award_section.title
          )}
        />
        {prizes?.digitalVouchers &&
          prizes.digitalVouchers.map((prize, index) => (
            <SimpleCard
              key={index}
              {...prize}
              path={`/digital-award-landing/${prizes.digitalVouchers[index].id}`}
            />
          ))
        }
      </div>
    </section>
  );
};

export default DigitalAwardSection;
