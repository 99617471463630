import locale from "../../assets/locale.json";
import { EnumIcon } from "../../models/enumIcon";
import "./ErrorMessageSubscribe.scss";
const ErrorMessageSubscribe = () => {
  return (
    <div className="pw-error-message-subscribe">
      <i className={`${EnumIcon.critical} pw-error-message-subscribe-icon`} />
      <span className="pw-error-message pw-error-p">
        {locale.error_message.checkbox_subscribe}
      </span>
    </div>
  );
};

export default ErrorMessageSubscribe;
