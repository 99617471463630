import "./ModalLogout.scss";
import Modal from "../Modal/Modal";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import logoutImg from "../../assets/images/logout.webp";
import { EnumIcon } from "../../models/enumIcon";
import { environment } from "../../environment/environment";
import { useNavigate } from "react-router-dom";
import useModal from "../../utils/useModalUtils";
import ModalError from "../ModalError/ModalError";
import { useAuthContext } from "../../utils/AuthContext";

interface ModalLogoutProps {
    isOpen: boolean;
    onClose: () => void;
}

const apiLogout = `${environment.apiDomain}/openid/logout`;

const ModalLogout: React.FC<ModalLogoutProps> = ({ isOpen, onClose }) => {
    const { altImg, button, description, title } = locale.logoutModal;
    const navigate = useNavigate();
    const { ModalType, isModalOpen, openModal, closeModal } = useModal();
    const { setIsAuth } = useAuthContext(); 

    const fetchLogout = async () => {
        try {
            const response = await fetch(apiLogout, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            if (response.ok) {
                onClose();
                setIsAuth(false);
                localStorage.removeItem("user");
                window.dispatchEvent(new Event("userRegistered"));
                navigate("/courtesy-landing");
            } else {
                openModal(ModalType.MODAL_ERROR);
            }
        } catch (error) {
            console.error(error);
            openModal(ModalType.MODAL_ERROR);
        }
    };

    return (
        <>
        <div className="pw-container-logout">
            <Modal isOpen={isOpen} onClose={onClose} >
                <SimpleContent
                    image={{ alt: altImg, src: logoutImg }}
                    title={title}
                    description={() => {
                        return (
                            <p className="logout-description paragraph-semi-bold-small">{description}</p>
                        );
                    }}
                    confirmationButton={{
                        theme: "blue",
                        label: button.confirmationLabel,
                        onClick: fetchLogout,
                    }}
                    cancellationButton={{
                        theme: "blue underline",
                        label: button.cancellationLabel,
                        onClick: onClose,
                        icon: EnumIcon.arrowLeft,
                        ariaLabel: button.cancellationLabel,
                    }}
                    isLogoutVariant
                />
            </Modal>
            <ModalError
                title={locale.modal_error.errorLogout.title}
                description={locale.modal_error.errorLogout.description}
                button={{
                    label: locale.modal_error.errorLogout.button.label,
                    theme: "blue",
                    onClick: () => closeModal(ModalType.CLOSE),
                }}
                isOpen={isModalOpen(ModalType.MODAL_ERROR)}
            /></div>
        </>
    )
}

export default ModalLogout