import "./ClaimPrize.scss";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import claimPrizeImg from "../../assets/images/claim-prize.webp";
import { useParseText } from "../../utils/useParseText";
import Button from "../Button/Button";
import { environment } from "../../environment/environment";

function ClaimPrize() {
  const { parseText } = useParseText();
  return (
    <div className="pw-claim-prize">
      <div className="pw-container">
        <SimpleContent
          image={{
            alt: locale.claim_prize.altImage,
            src: claimPrizeImg,
          }}
          title={locale.claim_prize.title}
          description={() => (
            <>
              <p
                className="pw-claim-prize-description"
                dangerouslySetInnerHTML={parseText(
                  locale.claim_prize.description
                )}
              />
              <Button
                theme="blue"
                label={
                  locale.claim_prize.label_button
                }
                url={environment.jakalaUrl}
                target="_blank"
              />
            </>
          )}
        />
      </div>
    </div>
  );
}

export default ClaimPrize;
