import { useState } from 'react';
import { blockScroll } from './blockScroll';

enum ModalType {
  CLOSE = "",
  LOGOUT = "logout",
  SUBSCRIBE = "subscribe",
  UNSUBSCRIBE = "unsubscribe",
  PLAY_GAME = "playGame",
  FINISHED_PLAYS = "finished_plays",
  DAYLY_LIMIT_REACHED = "daily_limit_reached",
  MODAL_ERROR = "modal_error",
  CANNOT_PARTICIPATE = "cannot_participate",
}

const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState<ModalType>(ModalType.CLOSE);

  const openModal = (type: ModalType) => {
    setModalIsOpen(type);
    blockScroll(true);
  };

  const closeModal = (type: ModalType) => {
    setModalIsOpen(type);
    blockScroll(false);
    window.scrollTo(0, 0);
  };

  const isModalOpen = (type: ModalType) => modalIsOpen === type;

  return {
    openModal,
    closeModal,
    isModalOpen,
    ModalType
  };
};

export default useModal;