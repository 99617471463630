/** 
 * EnumIcon is an enumeration that maps descriptive names to the corresponding CSS class names of icons. 
 * This allows for easy reference and usage of icons throughout the application. 
 * New icons can be added by simply appending them to the list with a descriptive name and the corresponding CSS class name.
 */

export enum EnumIcon {
    close = "icon-pwClose",
    help = "icon-pwHelp",
    facebook = "icon-pwFacebook",
    instagram = "icon-pwsocial-instagram",
    linkedin = "icon-pwLinkedin",
    twitter = "icon-pwtwitter",
    youtube = "icon-pwsocial-yt",
    arrowLeft = "icon-pwarrow-left",
    checkmark = "icon-pwcheckmark",
    arrowRight = "icon-pwgo-ahead",
    plus = "icon-pwplus",
    minus = "icon-pwminus",
    phone = "icon-pwphone",
    mail = "icon-pwmail",
    goBack = "icon-pwgo-back",
    chevronLeft = "icon-pwchevron-left",
    chevronRight = "icon-pwchevron-right",
    goAhead = "icon-pwgo-ahead",
    critical = "icon-pwCritical",
}