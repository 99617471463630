interface TrackingDataLayer {
    text_err_alert?: string;
    usr_id?: string;
    usr_id_redundant?: string;
    flag_profilazione?: string;
    tipo_login?: string;
    touchpoint: 'WEB';
    alias_page?: string;
    section?: string;
    title_page?: string;
    site_section: 'Pay and Win';
    info_prodotto_servizio_motivo?: string;
}

interface Satellite {
    track: (event: string) => undefined;
}

declare global {
    var dataLayer: TrackingDataLayer;
    var _satellite: Satellite;
}

export function track(data: TrackingDataLayer) {
    window.dataLayer = {
        touchpoint: "WEB",
        alias_page: data.alias_page,
        section: data.section,
        title_page: data.title_page,
        info_prodotto_servizio_motivo: data.info_prodotto_servizio_motivo,
        site_section: "Pay and Win",
        usr_id: sessionStorage.getItem('usrk') || '',
        usr_id_redundant: sessionStorage.getItem('usrk') || '',
        flag_profilazione: 'ND',
        tipo_login: 'Poste.it base'
    }

    window._satellite.track("direct_call_SPA");
}
