import "./CannotParticipate.scss";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import imgCannotPartecipate from "../../assets/images/cannot-partecipate.webp";
import HighlightedParagraph from "../HighlightedParagraph/HighlightedParagraph";
import { useNavigate } from "react-router-dom";
import { ContentProps } from "../../models/content";

const CannotParticipate: React.FC<ContentProps> = ({onClose = () => {}}) => {
  const backToHome = useNavigate();

  return (
    <div className="pw-cannot-partecipate-container">
      <SimpleContent
        image={{
          alt: locale.cannot_partecipate.alt_image,
          src: imgCannotPartecipate,
        }}
        title={locale.cannot_partecipate.title}
        subtitle={locale.cannot_partecipate.subtitle}
        description={() => (
          <HighlightedParagraph
            text={locale.cannot_partecipate.description}
            className="paragraph-cannot-partecipate paragraph-small"
          />
        )}
        confirmationButton={{
          theme: "blue",
          label: locale.cannot_partecipate.label_button,
          onClick: () => {
            onClose();
            backToHome(locale.cannot_partecipate.button_url)
          },
        }}
      />
    </div>
  );
};

export default CannotParticipate;