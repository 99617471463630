import { Fragment } from "react/jsx-runtime";
import DigitalAward from "../components/DigitalAward/DigitalAward";
import { IDigitalVoucher } from "../models/digitalVoucher";
import { EnumPrizeType } from "../models/prizes";
import usePrizes from "../utils/usePrizes";
import useSinglePrizeType from "../utils/useSinglePrizeType";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const DigitalAwardLanding = () => {
  const { id } = useParams<{ id: string }>();
  const { singlePrize, isLoading } = useSinglePrizeType<IDigitalVoucher>(EnumPrizeType.DIGITAL_VOUCHER, Number(id));
  const { prizes } = usePrizes();

  if (isLoading) {
    return <Loader />;
  }

  if (!singlePrize) {
    return <p>Digital award not found</p>;
  }

  return (
    <Fragment>
      {prizes?.digitalVouchers.map((voucher, index) => (
        <DigitalAward
          key={index}
          {...singlePrize}
          image={{ filename: voucher.image.filename, alt: voucher.image.alt }}
        />
      ))}
    </Fragment>
  );
};

export default DigitalAwardLanding;
