import React, { useEffect, useState } from "react";
import { ModalActionProps } from "../../models/modalAction";
import Modal from "../Modal/Modal";
import "./ModalUnsubscribe.scss";
import UnsubscribeCompleted from "../UnsubscribeCompleted/UnsubscribeCompleted";
import UnsubscribeConfirmed from "../UnsubscribeConfirmed/UnsubscribeConfirmed";
import CannotParticipate from "../CannotParticipate/CannotParticipate";
import { EnumContentModal } from "../../models/content";

const ModalUnsubscribe: React.FC<ModalActionProps> = ({ isOpen, onClose }) => {
  const [content, setContent] = useState<EnumContentModal>(EnumContentModal.UNSUBSCRIBE_CONFIRMED);

  const changeContent = (content: EnumContentModal) => {
    setContent(content);
  };

  useEffect(() => {
    if (!isOpen) {
      setContent(EnumContentModal.UNSUBSCRIBE_CONFIRMED);
    }
  }, [isOpen]);

  const renderContent = () => {
    switch (content) {
      case EnumContentModal.UNSUBSCRIBE_CONFIRMED:
        return <UnsubscribeConfirmed changeContent={changeContent} onClose={onClose}/>;

      case EnumContentModal.UNSUBSCRIBE_COMPLETED:
        return <UnsubscribeCompleted changeContent={changeContent} onClose={onClose} />;

      case EnumContentModal.CANNOT_PARTECIPATE:
        return <CannotParticipate onClose={onClose}/>;

      default:
        return <UnsubscribeConfirmed changeContent={changeContent} />;
    }
  };

  const modalContainer = 
    content === EnumContentModal.UNSUBSCRIBE_CONFIRMED 
      ? "pw-modal-unsubscribe-confirmed-container" 
      : content === EnumContentModal.UNSUBSCRIBE_COMPLETED
        ? "pw-modal-unsubscribe-completed-container"
        : "";
    
  return (
    <div className={modalContainer}>
      <Modal
        isCannotPartecipateContent = {content === EnumContentModal.CANNOT_PARTECIPATE}
        isOpen={isOpen}
        onClose={() => content !== EnumContentModal.UNSUBSCRIBE_COMPLETED && onClose()}
        className={
          content === EnumContentModal.CANNOT_PARTECIPATE ? "pw-cannot-partecipate-height" : ""
        }
        hideCloseButton={content === EnumContentModal.UNSUBSCRIBE_COMPLETED}
      >
        {renderContent()}
      </Modal>
    </div>
  );
};

export default ModalUnsubscribe;
