import React from "react";
import "./Modal.scss";
import locale from "../../assets/locale.json";
import Button from "../Button/Button";
import { EnumIcon } from "../../models/enumIcon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  closeButtonTheme?: string;
  className?: string;
  isCannotPartecipateContent?: boolean;
  hideCloseButton?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  fullWidth = false,
  closeButtonTheme,
  className,
  isCannotPartecipateContent,
  hideCloseButton = false,
}) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      className={`pw-modal-container ${fullWidth ? "pw-modal-full-width" : ""}`}
    >
      <div
        className={`pw-modal-content ${isCannotPartecipateContent ? "pw-modal-content-cannot-partecipate" : ""} ${className ? className : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="pw-modal-scroll-container">
          {children}
          {!hideCloseButton && 
            <Button
              className={`pw-modal-close-button ${closeButtonTheme ? closeButtonTheme : ""}` }
              theme={"white"}
              icon={EnumIcon.close}
              onClick={onClose}
              ariaLabel={locale.pre_registration.button_subscribe}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default Modal;
