import { CheckboxProps } from "../../models/checkbox";
import "./Checkbox.scss";
import React from "react";

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  disabled,
  className,
  name,
  onChange,
  checked
}) => {

  return (
    <label className="pw-container-checkbox" htmlFor={id} >
      <input
        className="pw-checkbox-input"
        type="checkbox"
        id={id}
        disabled={disabled}
        required
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span className="pw-focus-checkbox"></span>
      <span className={`pw-checkbox-span ${className}`}>
        {typeof label === "string" ? (
         <span className="pw-checkbox-label">{label}</span> 
        ) : (
          <span className="pw-checkbox-label" dangerouslySetInnerHTML={label} />
        )}
      </span>
    </label>
  );
};

export default Checkbox;
