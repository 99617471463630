import { HeroSection, PlayHeroSection } from "../components";
import DigitalAwardSection from "../components/DigitalAwardSection/DigitalAwardSection";
import DiscoverPrizeSection from "../components/DiscoverPrizeSection/DiscoverPrizeSection";
import Subscribe from "../components/Subscribe/Subscribe";
import Summary from "../components/Summary/Summary";
import ClaimPrize from "../components/ClaimPrize/ClaimPrize";
import ModalSubscribe from "../components/ModalSubscribe/ModalSubscribe";
import useModalUtils from "../utils/useModalUtils";
import "../assets/scss/page/Home.scss"
import { useUser } from "../utils/useUserExist";
import { useAuthContext } from "../utils/AuthContext";
import { track } from "../utils/Tracking";

export const Home = () => {
  const { openModal, closeModal, isModalOpen, ModalType } = useModalUtils();
  const user = useUser();
  const { isAuth } = useAuthContext();

  const openSubscribeModal = () => {
    track({
      touchpoint: 'WEB',
      alias_page: 'alias_payandwin_modale_chiusura',
      section: 'Iscrizione',
      title_page: 'Modale Chiusura Iscrizione',
      site_section: 'Pay and Win'
    });

    return openModal(ModalType.SUBSCRIBE);
  }

  return (
    <div className="pw-container-home-page">
      {isAuth || user ? (
        <>
          <PlayHeroSection />
          <DiscoverPrizeSection className="pw-play-discover-section" />
          <DigitalAwardSection />
          <ClaimPrize />
        </>
      ) : (
        <>
          <HeroSection />
          <Summary />
          <Subscribe showModal={openSubscribeModal} />
          <DiscoverPrizeSection />
          <DigitalAwardSection />
          <Subscribe showModal={openSubscribeModal} />
        </>
      )}

      <ModalSubscribe
        isOpen={isModalOpen(ModalType.SUBSCRIBE)}
        onClose={() => closeModal(ModalType.CLOSE)}
      />
    </div>
  );
};
