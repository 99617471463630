import React from "react";
import "./ModalGameWinExperienceAward.scss";
import SimpleContent from "../SimpleContent/SimpleContent";
import HighlightedParagraph from "../HighlightedParagraph/HighlightedParagraph";
import locale from "../../assets/locale.json";
import { EnumIcon } from "../../models/enumIcon";
import { useNavigate } from "react-router-dom";
import { PrizeAwardProps } from "../../models/prizeAward";
import { environment } from "../../environment/environment";

interface ModalGameWinExperienceAwardProps {
  onClose: () => void;
  prize: PrizeAwardProps;
}

const ModalGameWinExperienceAward: React.FC< ModalGameWinExperienceAwardProps>
= ({ onClose, prize }) => {
  let navigate = useNavigate();
  return (
    <div className="pw-game-win-experience-award-container">
      <SimpleContent
        headerLogo={locale.modal_game_win_experience_award.logo}
        image={{
          alt: prize.prize.image.alt,
          src: environment.apiImage + "/" + prize.prize.image.filename,
          copyright: prize.prize.image.copyright
        }}
        title={locale.modal_game_win_experience_award.title}
        subtitle={prize.prize.subtitle}
        description={() => (
          <HighlightedParagraph
            text={locale.modal_game_win_experience_award.description}
          />
        )}
        confirmationButton={{
          theme: "blue",
          label: locale.modal_game_win_experience_award.confirmation_label,
          onClick: () => {
            navigate(`/experience/${prize.prize.id}`);
          },
        }}
        cancellationButton={{
          theme: "blue underline",
          label: locale.modal_game_win_experience_award.cancellation_label,
          onClick: onClose,
          icon: EnumIcon.arrowLeft,
        }}
      />
    </div>
  );
};

export default ModalGameWinExperienceAward;
