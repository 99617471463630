import { useState, useEffect, useCallback } from "react";
import { User } from "../models/user";

export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);

  const checkUser = useCallback(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    checkUser();
    const handleUserUpdate = () => {
      checkUser();
    };
    window.addEventListener("userRegistered", handleUserUpdate);
    return () => {
      window.removeEventListener("userRegistered", handleUserUpdate);
    };
  }, [checkUser]);

  return user;
};
