import { Fragment } from "react/jsx-runtime"

const renderCopyright = (copyright: string) => {
    return copyright && (
      <Fragment>
        <div className="container-opacity" />
        <span className="pw-simple-card-copyright pw-copyright">{copyright}</span>
      </Fragment>
    )
}

export default renderCopyright