import "./HeroSection.scss";
import { Button } from "..";
import { useComponentVisible } from "../../utils/useDeviceSize";
import mastercard from "../../assets/images/mastercard.webp";
import locale from "../../assets/locale.json";
import { useParseText } from "../../utils/useParseText";
import useModalUtils from "../../utils/useModalUtils";
import ModalSubscribe from "../ModalSubscribe/ModalSubscribe";
import { useAuthContext } from "../../utils/AuthContext";
import CannotParticipate from "../CannotParticipate/CannotParticipate";
import Modal from "../Modal/Modal";
import { track } from "../../utils/Tracking";

const HeroSection = () => {
  const { isMobile } = useComponentVisible();
  const { parseText } = useParseText();
  const { heroSection: { title, subtitle, description, button } } = locale;
  const titleContent = parseText(title);
  const subtitleContent = parseText(subtitle);
  const { openModal, closeModal, isModalOpen, ModalType } = useModalUtils();
  const { responseError } = useAuthContext();
  const isNotCourtesyLanding = window.location.pathname !== "/courtesy-landing";

  const openSubscribeModal = () => {
    track({
      touchpoint: 'WEB',
      alias_page: 'alias_payandwin_modale_chiusura',
      section: 'Iscrizione',
      title_page: 'Modale Chiusura Iscrizione',
      site_section: 'Pay and Win'
    });

    return openModal(ModalType.SUBSCRIBE);
  }

  return (
    <section className={`pw-hero-section ${!isNotCourtesyLanding ? "hero-courtesy-landing" : ""}`}>
      <div className="hero-shadow" />
      <div className="pw-container">
        <div className="row">
          <div className="col col-sm-4 col-md-5 col-lg-4">
            <h1 className="hero-title title-one-bold-upper" id="title-hero" dangerouslySetInnerHTML={titleContent} />
            <h2 className="hero-subtitle paragraph-upper" dangerouslySetInnerHTML={subtitleContent} />
            <div className="hero-description">
              <p className="paragraph-xs-upper">{description}</p>
              <img
                src={mastercard}
                className="icon-mastercad"
                alt="mastercard"
              />
            </div>
            {!isMobile &&
              isNotCourtesyLanding &&
              <Button
                theme="white"
                label={button.label}
                onClick={() => responseError === 403 ? openModal(ModalType.CANNOT_PARTICIPATE) : openSubscribeModal()}
              />
            }
          </div>
        </div>
      </div>

      <>
        <ModalSubscribe isOpen={isModalOpen(ModalType.SUBSCRIBE)} onClose={() => closeModal(ModalType.CLOSE)} />
        <Modal
          isOpen={isModalOpen(ModalType.CANNOT_PARTICIPATE)}
          onClose={() => closeModal(ModalType.CLOSE)}
          isCannotPartecipateContent
        >
          <CannotParticipate onClose={() => closeModal(ModalType.CLOSE)} />
        </Modal>
      </>
    </section>
  );
};

export default HeroSection;
