import React from "react";
import { IButtonProps } from "../../models/button";
import "./Button.scss";
import { Link } from "react-router-dom";

const Button: React.FC<IButtonProps> = ({
  theme = "blue",
  onClick,
  url = "",
  label = "",
  target = "_self",
  icon = null,
  disabled = false,
  small = false,
  type = "button",
  className = "",
  ariaHidden,
  ariaLabel
}) => {
  const buttonClass = `pw-button button-label ${className} ${theme} ${small ? "small" : ""} ${icon ? "pw-button-icon" : ""}`;

  const contentLabel = (
    <>
      {icon && <i className={`${icon} pw-icon`} />}
      {label &&
        <span aria-hidden={ariaHidden}
          className="button-span">{label}
        </span>}
    </>
  );

  return (label || icon) ? (
    url ? (
      <Link
        className={`${buttonClass} pw-txt-none`}
        aria-label={ariaLabel}
        to={url}
        target={target}
      >
        {contentLabel}
      </Link>
    ) : (
      <button
        aria-label={ariaLabel}
        className={buttonClass}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        {contentLabel}
      </button>
    )
  ) : null;
};

export default Button;
