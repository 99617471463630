import "./PlayHeroSection.scss";
import Button from "../Button/Button";
import { useComponentVisible } from "../../utils/useDeviceSize";
import { EnumIcon } from "../../models/enumIcon";
import { useParseText } from "../../utils/useParseText";
import locale from "../../assets/locale.json";
import postepay from "../../assets/images/postepay.svg";
import playHeroImg from "../../assets/images/HeroImage.webp";
import pwPlayHeroImageMobile from "../../assets/images/imagePlayHeroMobile.webp";
import ModalPlayGame from "../ModalPlayGame/ModalPlayGame";
import ModalFinishedPlays from "../ModalFinishedPlays/ModalFinishedPlays";
import useModalUtils from "../../utils/useModalUtils";
import { useUser } from "../../utils/useUserExist";
import { track } from "../../utils/Tracking";

const PlayHeroSection = () => {
  const { isMobile } = useComponentVisible();
  const imgPay: string[] = Array(5).fill(postepay);
  const {
    title,
    subtitle,
    description,
    descriptionBottom,
    bettingCard,
    alt,
  } = locale.playHeroSection;
  const user = useUser();
  const { parseText } = useParseText();

  const transactionsProgressRemaining =
    5 - (user?.transactionsProgress || 0);
  const descriptionTransaction = description.replace(
    "{value}",
    String(transactionsProgressRemaining)
  );
  const descriptionProgressRemaining =
    transactionsProgressRemaining === 1
      ? descriptionTransaction.replace(
        "mancano 1 pagamenti",
        "manca 1 pagamento"
      )
      : descriptionTransaction;
  const { isModalOpen, openModal, closeModal, ModalType } = useModalUtils();

  const handlePlayGame = () => {
    if (user && user?.availableGames > 0) {
      
      track({
        touchpoint: 'WEB',
        alias_page: 'alias_payandwin_gioca',
        section: 'Gioca',
        title_page: 'Gioca',
        site_section: 'Pay and Win'
      });

      openModal(ModalType.PLAY_GAME);
    } else {
      track({
        touchpoint: 'WEB',
        alias_page: 'alias_payandwin_assenza_giocate',
        section: 'KO Page',
        title_page: 'Assenza Giocate Disponibili',
        site_section: 'Pay and Win'
      });

      openModal(ModalType.FINISHED_PLAYS);
    }
  };

  return (
    <>
      <section className="pw-play-hero-section">
        <div className="pw-container">
          <div className="row">
            <div className="pw-play-hero-container-title col-md-6">
              {!isMobile && (
                <img
                  className="pw-desktop-play-hero-img"
                  src={playHeroImg}
                  alt={bettingCard.alt}
                />
              )}
              <h1 className="title-two-bold pw-play-hero-title" dangerouslySetInnerHTML={parseText(title)} />
              <h2 className="subtitle-one pw-play-hero-subtitle">{subtitle}</h2>
            </div>
            <div className="pw-play-hero-container-desktop col-md-6">
              <div className="pw-play-hero-container-payment">
                <p
                  className="paragraph-small pw-play-hero-description"
                  dangerouslySetInnerHTML={parseText(
                    descriptionProgressRemaining
                  )}
                />

                <div className="pw-play-hero-postepay-cards-list">
                  {user &&
                    imgPay.map((img, i) => (
                      <div
                        key={i}
                        className={`pw-play-hero-postepay-container ${i >= user.transactionsProgress
                          ? "pw-play-hero-opacity"
                          : ""
                          }`}
                      >
                        <div className="pw-play-hero-single-postepay">
                          <img
                            src={img}
                            alt={alt}
                            className="pw-play-hero-postepay-img"
                          />
                        </div>
                      </div>
                    ))}
                </div>

                <p
                  className="paragraph-xs pw-play-hero-paragraph-bottom"
                  dangerouslySetInnerHTML={parseText(descriptionBottom)}
                />
              </div>

              <div className="pw-play-available">
                <div className="pw-play-available-container-content">
                  <div className="pw-play-available-content">
                    <p className="pw-play-available-number">
                      {user?.availableGames}
                    </p>
                    <p
                      className="pw-play-available-descriprtion paragraph-semi-bold-md-upper"
                      dangerouslySetInnerHTML={parseText(bettingCard.title)}
                    />
                  </div>
                  <Button
                    className="pw-play-available-button"
                    icon={EnumIcon.arrowRight}
                    theme="blue"
                    label={bettingCard.buttonLabel}
                    onClick={handlePlayGame}
                  />
                </div>

                {isMobile && (
                  <img
                    className="pw-play-available-img"
                    src={pwPlayHeroImageMobile}
                    alt={alt}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalPlayGame
        isOpen={isModalOpen(ModalType.PLAY_GAME)}
        onClose={() => {
          closeModal(ModalType.CLOSE);
        }}
      />

      <ModalFinishedPlays
        isOpen={isModalOpen(ModalType.FINISHED_PLAYS)}
        onClose={() => closeModal(ModalType.CLOSE)}
      />
    </>
  );
};

export default PlayHeroSection;
